import React from 'react'
import Form from '../Components/Form'
import Bg from '../assets/bg.svg'
import { Grid } from '@mui/material'
import { ReactComponent as Wines } from '../assets/wines.svg';
import useWindowDimensions from '../hooks/usewindowdimensions';
import Button from '../Components/Button';
import styled from 'styled-components';
import Desc from '../Components/Desc';


const TitleWrapp = styled.div`
    display:block;
    font-size:36px;
    font-weight:800;
    line-height:38px;
    color:#000000;
    text-align:center;
    width:100%;
    max-width:300px;
`

const RedText = styled.span`
    font-size:36px;
    font-weight:800;
    line-height:38px;
    color:#A70500;
   
    @media (max-width: 899.98px) {
        font-weight:1000;
        font-family: 'Roboto Flex', sans-serif !important;
    }

    @media (max-width: 599.98px) { 
        font-weight:1000;
        font-family: 'Roboto Flex', sans-serif !important;
    }
   
`
const Home = () => {

    const { width } = useWindowDimensions()

    return (
        <div style={{ backgroundImage: `url(${Bg})`, backgroundSize: width < 899.99 ? 'cover' : "contain", backgroundRepeat: 'no-repeat', height: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid container flexDirection={width < 899.99 && "column"} justifyContent={width < 899.99 ? "center" : 'space-around'} rowGap={3} alignItems={'center'} paddingTop={(width < 1200 && width > 899.99) && 50} >
                {width > 899.99 ? (
                    <Grid item>
                        <Form />
                    </Grid>
                ) : (
                    <Grid container justifyContent={'center'} rowGap={3} maxWidth={'350px'}>
                        <TitleWrapp>Join our survey for a chance to win a wine collection worth <RedText>600 CHF!</RedText></TitleWrapp>
                        <Desc />
                        <Button text={'SUBMIT'} link={'/fillform'} res={true} />
                    </Grid>
                )}

                <Grid items>
                    <Wines style={{ width: "100%", height: "auto", maxWidth: width < 899.99 ? "350px" : "600px" }} />
                </Grid>
            </Grid>
        </div>
    )
}

export default Home