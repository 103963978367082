import React, { useState } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Desc from './Desc'
import Input from './Input'
import Button from './Button'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Form = styled.form`
    width:100%;
    max-width:325px;
    border-radius:8px;
    border:1px solid #E8E8E8;
    background-color:#FFFFFF;
    padding:34px 22px;
    display:flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    box-shadow: 0px 4px 16.2px 0px rgba(186, 186, 186, 0.25);
`

const TitleWrapp = styled.div`
    font-size:20px;
    font-weight:800;
    line-height:38px;
`

const ResForm = () => {

    const [formData, setformData] = useState({
        name: '',
        surname: '',
        phone: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setformData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData);

        const data = {
            name: formData.name,
            surname: formData.surname,
            phone: formData.phone,
        }

        try {
            const response = await axios.post("https://email.kutiza.com/send-email-Gewinne", data);
            console.log('Response:', response.data);
            navigate('/thanku');

        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                console.error('Status Code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };


    return (
        <Form onSubmit={handleSubmit}>
            <Grid container justifyContent={'center'} rowGap={3}>
                <Grid item>
                    <Grid container >
                        <Grid item>
                            <TitleWrapp>Fill in the form</TitleWrapp>
                        </Grid>
                        <Grid item>
                            <Desc desc={'Thank you for participating in our wine preferences survey.'} style={{ color: "#7C7C7C", textAlign: "left" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Input label={'NAME'} placeholder={'Write your name'} name={'name'} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <Input label={'SURNAME'} placeholder={'Write your surname'} name={'surname'} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <Input label={'PHONE NUMBER'} placeholder={'+41'} name={'phone'} onChange={handleChange} maxLength={10} minLength={10} />
                </Grid>
                <Grid item xs={12}>
                    <Button text={'SUBMIT'} />
                </Grid>
            </Grid>
        </Form>
    )
}

export default ResForm