import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Pages/Home';
import ThankU from './Pages/ThankU';
import FormPage from './Pages/FormPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/fillform' element={<FormPage />} />
        <Route path='/thanku' element={<ThankU />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
