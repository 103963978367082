import React from 'react'
import styled from 'styled-components'

const InputWrapp = styled.div`
    border:1px solid #BBBBBB;
    padding:15px 22px;
`

const InputComponent = styled.input`
    width:100%;
    border:none;
    outline:none;
`

const Label = styled.label`
    font-size:12px;
    font-weight:500;
    line-height:16.9px;
    color:#00000066;
`

const PhoneInput = ({ placeholder, label, onChange, value, name, setFormData, formData }) => {
    return (
        <Label>
            {label}
            <InputWrapp>
                <InputComponent
                    pattern="^\+41(?:[\.\-\s]?(21|22|24|26|27|31|32|33|34|41|43|44|51|52|55|56|58|61|62|71|74|76|77|78|79|81|91))(?:[\.\-\s]?\d\d\d)(?:[\.\-\s]?\d\d){2}$"
                    minLength={12}
                    maxLength={12}
                    onChange={onChange}
                    autoComplete='new-password'
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    style={{ border: "none", paddingLeft: "10px", outline: "none" }}
                />
            </InputWrapp>
        </Label>
    )
}

export default PhoneInput