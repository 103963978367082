import { Grid } from '@mui/material'
import React from 'react'
import Thankusvg from '../assets/thanku.svg'
import ResThankusvg from '../assets/resThanku.svg'
import styled from 'styled-components'
import useWindowDimensions from '../hooks/usewindowdimensions'

const Title = styled.div`
    font-weight:1000;
    font-size:84px;
    line-height:93px;
    width:100%;
    max-width:550px;
    color:#A70500;
    text-align:center;
    font-family: 'Roboto Flex', sans-serif;

    @media (max-width: 899.98px) {
        font-size:44px;
        line-height:38px;
        max-width:250px;
    }

    @media (max-width: 599.98px) { 
        font-size:44px;
        line-height:38px;
        max-width:250px;
    }
`

const Desc = styled.div`
    font-weight:500;
    font-size:28px;
    line-height:37px;
    width:100%;
    max-width:600px;
    color:#450200;
    text-align:center;
`


const ThankU = () => {

    const { width } = useWindowDimensions();

    return (
        <Grid container flexDirection={'column'} rowGap={3} justifyContent={'center'} alignItems={'center'}  style={{ backgroundImage:width> 899.99 ? `url(${Thankusvg})`:`url(${ResThankusvg})` , backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '100vh', backgroundPosition: "center" }}>
            <Grid item>
                <Title>Thank You for Your
                    Submission! </Title>
            </Grid>
            {width > 899.99 && (
                <Grid item>
                    <Desc>Your responses have been successfully submitted. We appreciate your time and input.</Desc>
                </Grid>
            )}

        </Grid>
    )
}

export default ThankU