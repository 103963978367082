import React, { useState } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Desc from './Desc'
import Input from './Input'
import Button from './Button'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from './PhoneInput'

const FormWrapper = styled.form`
    width:100%;
    max-width:700px;
    border-radius:2px;
    border:1px solid #FFFFFF;
    background-color:#FFFFFF;
    padding:50px;
    display:flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1440px) {
        max-width:500px;
    }
`

const TitleWrapp = styled.div`
    display:block;
    font-size:41px;
    font-weight:800;
    line-height:42px;
    color:#000000;
    text-align:center;
    width:100%;
    max-width:600px;


    @media only screen and (max-width: 1440px) {
        font-size:30px;
    }
`

const RedText = styled.span`
    font-size:41px;
    font-weight:800;
    line-height:42px;
    color:#A70500;

    @media only screen and (max-width: 1440px) {
        font-size:30px;
    }
`

const Form = () => {

    const [formData, setformData] = useState({
        name: '',
        surname: '',
        phone: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setformData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: formData.name,
            surname: formData.surname,
            phone: formData.phone,
        }

        try {
            const response = await axios.post("https://email.kutiza.com/send-email-Wines", data);
            console.log('Response:', response.data);
            navigate('/thanku');

        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                console.error('Status Code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };

    return (
        <FormWrapper onSubmit={handleSubmit}>
            <Grid container justifyContent={'center'} rowGap={3}>
                <Grid item>
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            <TitleWrapp>Join our survey for a chance to win a wine collection worth <RedText>600 CHF</RedText>! </TitleWrapp>
                        </Grid>
                        <Grid item>
                            <TitleWrapp>Open to all wine enthusiasts</TitleWrapp>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Desc desc={'Thank you for participating in our wine preferences survey. Your input will help us curate a better wine selection for our customers. Please take a moment to share your preferences and experiences with us.'} />
                </Grid>
                <Grid item xs={12}>
                    <Input placeholder={'NAME'} name={'name'} value={formData.name} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <Input placeholder={'SURNAME'} name={'surname'} value={formData.surname} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <PhoneInput placeholder={'PHONE NUMBER'} onChange={handleChange} value={formData.phone} name={'phone'} maxLength={10} minLength={10} />
                </Grid>
                <Grid item xs={12}>
                    <Button text={'SUBMIT'} />
                </Grid>
            </Grid>
        </FormWrapper>
    )
}

export default Form