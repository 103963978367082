import React from 'react'
import styled from 'styled-components'

const InputWrapp = styled.div`
    border:1px solid #BBBBBB;
    padding:15px 22px;
`

const InputComponent = styled.input`
    width:100%;
    border:none;
    outline:none;
`

const Label = styled.label`
    font-size:12px;
    font-weight:500;
    line-height:16.9px;
    color:#00000066;
`

const Input = ({ placeholder, label ,onChange, maxLength, minLength, name, value}) => {
    return (
        <Label>
            {label}
            <InputWrapp>
                <InputComponent placeholder={placeholder} name={name} value={value} required onChange={onChange} maxLength={maxLength} minLength={minLength}/>
            </InputWrapp>
        </Label>
    )
}

export default Input