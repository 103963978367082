import React from 'react'
import Bg from '../assets/bg.svg'
import DottedLine from '../assets/dottedline.png'
import useWindowDimensions from '../hooks/usewindowdimensions'
import ResForm from '../Components/ResForm'
import { Grid } from '@mui/material'

const FormPage = () => {

  const { width } = useWindowDimensions();
  
  return (
    <div style={{ backgroundImage: `url(${Bg})`, backgroundSize: width < 899.99 ? 'cover' : "contain", backgroundRepeat: 'no-repeat', height: '100vh' }}>
      <Grid container justifyContent={'center'} alignItems={'center'} py={'50px'}
        style={{
          backgroundImage: `url(${DottedLine})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          backgroundPosition: 'center'
        }}>
        <ResForm />
      </Grid>
    </div>
  )
}

export default FormPage